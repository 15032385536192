<template>
  <Modal nome="criarTemplate">
    <!-- Campo -->
    <label class="um">
      Nome do template
      <span>*</span>
    </label>
    <input type="text" spellcheck="false" placeholder="Nome aqui..." v-model="state.nome" />
    <!-- Campo -->
    <label>
      Descrição do template
      <span>*</span>
    </label>
    <textarea rows="2" spellcheck="false" placeholder="Descrição aqui..." v-model="state.descricao" v-redimensionar-textarea></textarea>
    <!-- Campo -->
    <label>
      Hotlink do template
      <span>*</span>
    </label>
    <div class="inputUrl">
      <p>unic.page/</p>
      <input type="text" v-model="state.hotlink" spellcheck="false" placeholder="Digite o hotlink" />
    </div>
    <!-- Campo -->
    <label>
      Categoria do template
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" class="icone" />
      <select v-model="state.categoria">
        <option value="academia">Academia</option>
        <option value="academia-de-luta">Academia de Luta</option>
        <option value="acai">Açaí</option>
        <option value="acougue">Açougue</option>
        <option value="assistencia-tecnica">Assistência Técnica</option>
        <option value="cabeleireiro">Cabeleireiro</option>
        <option value="consultorio-medico">Consultório Médico</option>
        <option value="contabilidade">Contabilidade</option>
        <option value="conveniencia">Conveniência</option>
        <option value="dentista">Dentista</option>
        <option value="escola-de-idiomas">Escola de Idiomas</option>
        <option value="escola-e-faculdade">Escola e Faculdade</option>
        <option value="escritorio-advocacia">Escritório Advocacia</option>
        <option value="estetica-automotiva">Estética Automotiva</option>
        <option value="farmacia">Farmácia</option>
        <option value="hamburgueria">Hamburgueria</option>
        <option value="imobiliaria">Imobiliária</option>
        <option value="link-de-bio">Link de Bio</option>
        <option value="loja-de-moveis">Loja de Móveis</option>
        <option value="loja-de-roupa">Loja de Roupa</option>
        <option value="loja-de-tintas">Loja de Tintas</option>
        <option value="maquiadora">Maquiadora</option>
        <option value="marketing-digital">Marketing Digital</option>
        <option value="materiais-de-construcao">Materiais de Construção</option>
        <option value="mercearia">Mercearia</option>
        <option value="mecanica">Mecânica</option>
        <option value="padaria">Padaria</option>
        <option value="papelaria">Papelaria</option>
        <option value="pet-shop">Pet Shop</option>
        <option value="portfolio">Portfólio</option>
        <option value="sorveteria">Sorveteria</option>
      </select>
    </div>
    <!-- Campo -->
    <label>Tags para busca do usuário</label>
    <div class="textarea">
      <textarea rows="2" spellcheck="false" v-model="state.tags" placeholder="tag, tag, tag" v-redimensionar-textarea></textarea>
    </div>
    <!-- Campo -->
    <label>
      Imagem do Template
      <span>*</span>
    </label>
    <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
      <span></span>
      <div class="previa" :style="`background-image: url('${state.imagemCapa}')`">
        <Svgs v-if="!state.imagemCapa" nome="arquivo" />
      </div>
      <div class="texto">
        <h3>{{ state.nomeArquivo || 'Subir Imagem' }}</h3>
        <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 600x450' }}</p>
      </div>
      <input type="file" accept=".jpg,.jpeg,.png,.webp" hidden @change="selecionarImagem" ref="inputFile" />
    </div>

    <BotaoSalvar nome="Criar novo template" @click="salvar" />
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject, ref } from 'vue'
import { useStoreTemplates } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'
import ApiUpload from '@/api/upload/api-upload.js'

const emitter = inject('emitter')
const storeTemplates = useStoreTemplates()

const inputFile = ref(null)

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  categoria: 'marketing-digital',
  tags: '',
  imagemPerfil: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 900
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.imagemCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem do template' })
    })
}

async function salvar() {
  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    categoria: state.categoria,
    tags: state.tags.split(',').map((tag) => tag.trim()),
    imagemCapa: state.imagemCapa
  }

  if (state.nome || state.descricao || state.hotlink || state.categoria || state.imagemCapa) await storeTemplates.adicionar(payload)

  setTimeout(() => {
    window.location.reload()
  }, 500)
}

function limparCampos() {
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.imagemCapa = ''
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.categoria = 'marketing-digital'
  state.tags = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label.um {
  margin: 0 0 10px 0;
}

button {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
