import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiTemplates from '../api/unicpages/api-templates'

export const useStoreTemplates = defineStore('storeTemplates', {
  state: () => {
    return {
      templates: []
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiTemplates.receberTodos()
        this.templates = resp.data.body
        return true
      } catch (error) {
        this.templates = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async adicionar(payload) {
      try {
        const resp = await apiTemplates.adicionar(payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(idTemplate, payload) {
      try {
        const resp = await apiTemplates.alterarDados(idTemplate, payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idTemplate) {
      try {
        const resp = await apiTemplates.deletar(idTemplate)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
