<template>
  <Modal nome="verTemplate" class="template">
    <div class="topo" :style="`background-image: url('${state.template?.imagemCapa || ''}')`">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${state.template?.imagemCapa || ''}')`"></div>
        </div>
      </div>
      <div class="texto">
        <h2>{{ state.template?.nome }}</h2>
        <h3>{{ categoriasMapeadas[state.template?.categoria] || state.template?.categoria }}</h3>
        <p>{{ state.template?.descricao }}</p>
      </div>
      <div class="sombra"></div>
    </div>
    <div class="opcoes">
      <button @click="abrirEditar(state.template)">
        <Svgs nome="editar" />
        <h3>Editar</h3>
      </button>
      <button @click="abrirExcluir(state.template)">
        <Svgs nome="excluir" />
        <h3>Excluir</h3>
      </button>
      <button @click="state.visualizar = !state.visualizar">
        <Svgs nome="visualizar" />
        <h3>Visualizar</h3>
      </button>
    </div>
    <div class="dados">
      <h3>Tags de busca</h3>
      <p>{{ state.template?.tags?.join(', ') }}</p>
    </div>
  </Modal>
  <div class="iframe" v-if="state.visualizar">
    <button class="fechar" @click="state.visualizar = !state.visualizar">
      <Svgs nome="x" />
    </button>
    <iframe :src="`https://unic.page/${state.template?.hotlink}`"></iframe>
    <div class="borda"></div>
  </div>
</template>

<script setup>
import { reactive, onMounted, watch, inject, computed } from 'vue'
import { useStoreModal } from '@stores'
import Modal from '@/components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('verTemplate'))

const state = reactive({
  template: '',
  visualizar: false
})

const categoriasMapeadas = {
  'academia': 'Academia',
  'academia-de-luta': 'Academia de Luta',
  'acai': 'Açaí',
  'acougue': 'Açougue',
  'assistencia-tecnica': 'Assistência Técnica',
  'cabeleireiro': 'Cabeleireiro',
  'consultorio-medico': 'Consultório Médico',
  'contabilidade': 'Contabilidade',
  'conveniencia': 'Conveniência',
  'dentista': 'Dentista',
  'escola-de-idiomas': 'Escola de Idiomas',
  'escola-e-faculdade': 'Escola e Faculdade',
  'escritorio-advocacia': 'Escritório Advocacia',
  'estetica-automotiva': 'Estética Automotiva',
  'farmacia': 'Farmácia',
  'hamburgueria': 'Hamburgueria',
  'imobiliaria': 'Imobiliária',
  'link-de-bio': 'Link de Bio',
  'loja-de-moveis': 'Loja de Móveis',
  'loja-de-roupa': 'Loja de Roupa',
  'loja-de-tintas': 'Loja de Tintas',
  'maquiadora': 'Maquiadora',
  'marketing-digital': 'Marketing Digital',
  'materiais-de-construcao': 'Materiais de Construção',
  'mercearia': 'Mercearia',
  'mecanica': 'Mecânica',
  'padaria': 'Padaria',
  'papelaria': 'Papelaria',
  'pet-shop': 'Pet Shop',
  'portfolio': 'Portfólio',
  'sorveteria': 'Sorveteria'
}

function abrirEditar(template) {
  storeModal.fecharModal('verTemplate')
  storeModal.abrirModalEdicao('editarTemplate', 'editar', 'Editar Template', template)
}

function abrirExcluir(template) {
  storeModal.fecharModal('verTemplate')
  storeModal.abrirModalEdicao('excluirTemplate', 'excluir', 'Excluir Template', template)
}

async function pegarDados() {
  if (dadosModal.value.id) {
    state.template = dadosModal.value.id
  }
}

function limparCampos() {
  state.page = []
  state.hotlinkSite = ''
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarDados()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.iframe {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: var(--cor-preto-transparente);
  animation: fadeIn 0.3s linear;
}

.iframe iframe {
  position: absolute;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  border-radius: 10px;
  z-index: 2;
}

.iframe .borda {
  position: absolute;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: var(--cor-cinza-1);
  border: 15px solid var(--cor-cinza-1);
  border-radius: 15px;
  z-index: 1;
}

.iframe button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-color: var(--cor-vermelho);
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 3;
  transition: all 0.3s;
}

.iframe button:hover {
  background-color: var(--cor-vermelho-escuro);
}

.iframe button svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco-fixo);
}

.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding: 80px 0 0 0;
  width: 100%;
}

.topo .sombra {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, var(--cor-cinza-2), transparent);
  backdrop-filter: blur(50px);
  border-radius: 10px 10px 0 0;
  z-index: 1;
}

.topo .capa {
  width: 100%;
  max-width: 300px;
  z-index: 2;
}

.topo .capa .tamanho {
  padding-bottom: 75%;
  width: 100%;
  position: relative;
}

.topo .capa .tamanho .imagem {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 0 var(--cor-preto-transparente);
}

.topo .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  margin: 30px 0 0 0;
  width: 100%;
}

.topo .texto h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.topo .texto h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 5px 0 10px 0;
}

.topo .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 500px;
}

.opcoes {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 40px 0;
}

.opcoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin: 0 5px;
  padding: 20px 0 15px 0;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.opcoes button:hover {
  background-color: var(--cor-cinza-4);
}

.opcoes button svg {
  width: 30px;
  min-width: 30px;
  max-height: 30px;
  fill: var(--cor-branco);
  margin: 0 0 13px 0;
}

.opcoes button h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
}

.dados {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  border-top: 1px solid var(--cor-cinza-3);
}

.dados h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.dados p {
  font-family: var(--regular);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .iframe iframe {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
  }

  .iframe .borda {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border: 10px solid var(--cor-cinza-1);
  }

  .iframe button {
    width: 60px;
    height: 60px;
    top: 10px;
    right: 10px;
  }

  .iframe button svg {
    width: 20px;
    min-width: 20px;
  }

  .topo {
    border-radius: 0;
    padding: 70px 20px 20px 20px;
  }

  .topo .sombra {
    border-radius: 0;
  }

  .opcoes {
    margin: 0 0 30px 0;
  }

  .opcoes button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85px;
    padding: 20px 0 15px 0;
    border-radius: 10px;
    background-color: var(--cor-cinza-3);
    transition: all 0.3s;
  }

  .opcoes button svg {
    width: 20px;
    min-width: 20px;
    max-height: 20px;
  }

  .opcoes button h3 {
    font-size: var(--f1);
  }
}
</style>
