import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  receberTodos() {
    const method = 'GET'
    const url = `templates`

    return api(method, url, null, token)
  },

  adicionar(payload) {
    const method = 'POST'
    const url = 'templates'

    return api(method, url, payload, token)
  },

  alterarDados(idTemplate, payload) {
    if (!idTemplate) return

    const method = 'PUT'
    const url = `templates/${idTemplate}`

    return api(method, url, payload, token)
  },

  deletar(idTemplate) {
    if (!idTemplate) return

    const method = 'DELETE'
    const url = `templates/${idTemplate}`

    return api(method, url, {}, token)
  }
}
