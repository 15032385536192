<template>
  <Modal nome="excluirTemplate">
    <div class="conteudo">
      <div class="item">
        <div class="info">
          <div class="nome">
            <h3>{{ state.template?.nome }}</h3>
          </div>
          <div class="status on">
            <span></span>
            <h3>Ativo</h3>
          </div>
        </div>
        <div class="links">
          <p class="link dominio" target="_blank">{{ state.template?.categoria }}</p>
          <a class="link hotlink" target="_blank" :href="`https://unic.page/${state.template?.hotlink}`">unic.page/{{ state.template?.hotlink }}</a>
        </div>
      </div>

      <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
        <span>
          <Svgs nome="check" />
        </span>
        <p>Sim, desejo excluir este template</p>
      </div>

      <BotaoExcluir nome="Excluir Template" @click="excluirItem()" />
    </div>
  </Modal>
</template>

<script setup>
import { inject, computed, onMounted, watch, reactive } from 'vue'
import { useStoreTemplates, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoExcluir from '@components/global/elementos/BotaoExcluir.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')

const storeTemplates = useStoreTemplates()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('excluirTemplate'))

const state = reactive({
  template: [],
  confirmar: false
})

async function excluirItem() {
  if (state.confirmar === true) {
    const delecaoOk = await storeTemplates.deletar(state.template?._id)
    if (delecaoOk !== true) return

    setTimeout(() => {
      window.location.reload()
    }, 500)
  } else {
    const mensagem = 'Marque a pergunta para excluir'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
  }
}

async function carregarTemplate() {
  if (dadosModal.value.id) {
    state.template = dadosModal.value.id
  }
}

function limparCampos() {
  state.template = []
  state.confirmar = false
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        carregarTemplate()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .foto {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--cor-cinza-3);
  position: relative;
}

.item .foto .icone {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: var(--cor-roxo);
  transition: all 0.3s;
  position: absolute;
  right: -3px;
  top: -3px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-azul);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links .link.dominio {
  border-right: 2px solid var(--cor-cinza-3);
}

.item .links .link {
  text-align: center;
  width: 50%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  padding: 15px 5px;
  transition: all 0.3s;
}

.item .links a.link:hover {
  color: var(--cor-azul);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links {
    flex-direction: column;
  }

  .item .links .link.dominio {
    border-bottom: 2px solid var(--cor-cinza-3);
    border-right: none;
  }

  .item .links .link {
    width: 100%;
    font-size: var(--f0);
    padding: 13px 5px;
  }
}
</style>
