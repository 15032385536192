<template>
  <section class="lista">
    <div class="topo">
      <div class="total">
        <Svgs nome="cursor" />
        <h3>{{ storeTemplates.templates?.length }} templates Unic</h3>
      </div>
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" spellcheck="false" placeholder="Buscar template" v-model="termoBusca" @input="filtrarTemplates" />
      </div>
      <button class="criar" @click="storeModal.abrirModal('criarTemplate', 'template', 'Criar novo template')">
        <Svgs nome="mais" />
        <p>Novo template</p>
      </button>
    </div>

    <div class="categorias" v-for="(templates, categoria) in templatesPorCategoria" :key="categoria">
      <h2>{{ categoriasMapeadas[categoria] || categoria }}</h2>
      <div class="itens">
        <div class="item" v-for="template in templates" :key="template._id" @click="storeModal.abrirModalEdicao('verTemplate', 'template', 'Visualizar Template', template)">
          <div class="conteudo">
            <div class="info">
              <h3>{{ template.nome }}</h3>
              <p>/{{ template.hotlink }}</p>
            </div>
            <div class="capa">
              <div class="tamanho">
                <div class="imagem" :style="`background-image: url('${template.imagemCapa || ''}')`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watchEffect, ref, inject } from 'vue'
import { useStoreTemplates, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storeTemplates = useStoreTemplates()
const termoBusca = ref('')
const templatesPorCategoria = ref({})
const emitter = inject('emitter')

const categoriasMapeadas = {
  'academia': 'Academia',
  'academia-de-luta': 'Academia de Luta',
  'acai': 'Açaí',
  'acougue': 'Açougue',
  'assistencia-tecnica': 'Assistência Técnica',
  'cabeleireiro': 'Cabeleireiro',
  'consultorio-medico': 'Consultório Médico',
  'contabilidade': 'Contabilidade',
  'conveniencia': 'Conveniência',
  'dentista': 'Dentista',
  'escola-de-idiomas': 'Escola de Idiomas',
  'escola-e-faculdade': 'Escola e Faculdade',
  'escritorio-advocacia': 'Escritório Advocacia',
  'estetica-automotiva': 'Estética Automotiva',
  'farmacia': 'Farmácia',
  'hamburgueria': 'Hamburgueria',
  'imobiliaria': 'Imobiliária',
  'link-de-bio': 'Link de Bio',
  'loja-de-moveis': 'Loja de Móveis',
  'loja-de-roupa': 'Loja de Roupa',
  'loja-de-tintas': 'Loja de Tintas',
  'maquiadora': 'Maquiadora',
  'marketing-digital': 'Marketing Digital',
  'materiais-de-construcao': 'Materiais de Construção',
  'mercearia': 'Mercearia',
  'mecanica': 'Mecânica',
  'padaria': 'Padaria',
  'papelaria': 'Papelaria',
  'pet-shop': 'Pet Shop',
  'portfolio': 'Portfólio',
  'sorveteria': 'Sorveteria'
}

function carregarTemplates() {
  storeTemplates.receberTodos()
  filtrarTemplates()
}

function filtrarTemplates() {
  const termo = termoBusca.value.trim().toLowerCase()
  const agrupadosPorCategoria = {}

  const templatesFiltrados =
    termo === ''
      ? storeTemplates.templates
      : storeTemplates.templates.filter((template) => {
          return template.nome?.toLowerCase().includes(termo) || template.hotlink.toLowerCase().includes(termo) || template.tags?.some((tag) => tag.toLowerCase().includes(termo))
        })

  templatesFiltrados.forEach((template) => {
    if (!agrupadosPorCategoria[template.categoria]) {
      agrupadosPorCategoria[template.categoria] = []
    }
    agrupadosPorCategoria[template.categoria].push(template)
  })

  templatesPorCategoria.value = agrupadosPorCategoria
}

watchEffect(() => {
  filtrarTemplates()
})

onMounted(() => {
  emitter.on('carregarTemplates', carregarTemplates())
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0 0 0;
  min-height: 100dvh;
}

.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 30px 20px;
}

.topo .total {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 0 0;
}

.topo .total svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.topo .total h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.topo button.criar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 0 20px;
  border-radius: 10px;
  margin: 0 0 0 15px;
  width: 250px;
  transition: all 0.3s;
}

.topo button.criar:hover {
  background-color: var(--cor-azul-escuro);
}

.topo button.criar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.topo button.criar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.categorias {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 30px 0;
}

.categorias h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 20px;
}

.itens {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  padding: 20px 20px 0 20px;
  scrollbar-width: none;
}

.itens::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 8px;
  margin: 0 15px 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.item:hover {
  border: 1px solid var(--cor-cinza-4);
}

.item .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 20px 10px;
}

.item .info h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .info p {
  font-family: var(--light);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
}

.item .capa {
  width: 100%;
}

.item .capa .tamanho {
  padding-bottom: 75%;
  position: relative;
  width: 100%;
}

.item .capa .tamanho .imagem {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px var(--cor-cinza-1);
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    display: flex;
    flex-direction: column;
  }

  .topo {
    flex-direction: column;
  }

  .topo .total {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    padding: 15px;
    margin: 0 0 15px 0;
  }

  .topo button.criar {
    padding: 15px 20px;
    margin: 15px 0 0 0;
    width: 100%;
  }

  .item {
    width: 100%;
  }

  .item .botoes button {
    width: 60px;
  }
}
</style>
