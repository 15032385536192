<template>
  <section class="modal" :class="{ mostrar: mostrarModalSites }">
    <div class="conteudo">
      <div class="titulo">
        <div class="foto" :style="`background-image: url('${storeUsuarios.modals.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="texto">
          <h3>{{ storePages.pagesUsuario?.length }} sites criados pelo usuário</h3>
          <p>{{ storeUsuarios.modals.email }}</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="lista">
        <h6 v-if="!storePages.pagesUsuario.length">Nenhum site criado pelo usuário</h6>
        <div v-for="page in storePages.pagesUsuario" :key="page.hotlink" class="site">
          <div class="nome">
            <h4 v-if="page.online === true" class="online">
              <span></span>
              Online
            </h4>
            <h4 v-if="page.online === false" class="offline">
              <span></span>
              Offline
            </h4>
            <h3>unic.page/{{ page.hotlink }}</h3>
            <p :class="{ com: page.dominioPersonalizado }">{{ page.dominioPersonalizado || 'Sem domínio' }}{{ page.dominioRota ? '/' + page.dominioRota : '' }}</p>
            <h5>Criado {{ formatarData(page.dataRegistro) }}</h5>
          </div>
          <div class="botoes">
            <button @click="verSite(page.hotlink)">
              <Svgs nome="link" />
              Hotlink
            </button>
            <button v-if="page.dominioPersonalizado" @click="verDominio(page.dominioPersonalizado)">
              <Svgs nome="dominio" />
              Domínio
            </button>
            <button @click="abrirModalTransferencia(page._id)">
              <Svgs nome="seta" />
              Transferir
            </button>
          </div>
        </div>
      </div>
      <!-- Usuário p/ transferir domínio -->
      <div class="usuarios" v-if="state.mostrarModalTransferencia">
        <div class="topo">
          <h3>Selecione para quem deseja transferir</h3>
          <button class="fechar" @click="fecharModalTransferencia()"><Svgs nome="x" /></button>
        </div>
        <div class="input">
          <Svgs class="icone" nome="lupa" />
          <input type="text" spellcheck="false" placeholder="Buscar usuário" v-model="termoBusca" @input="filtrarUsuarios" />
        </div>
        <button class="transferir" @click="confirmarTransferencia()">Transferir para usuário selecionado</button>
        <div class="listaUsuarios">
          <div class="usuario" v-for="usuario in usuariosFiltrados" :key="usuario._id" :class="{ ativo: state.idUsuarioTransferir === usuario._id }" @click="selecionarUsuarioTransferir(usuario._id)">
            <div class="info">
              <div class="foto" :style="`background-image: url('${usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
              <div class="nome">
                <h3>{{ usuario?.nome }}</h3>
                <p>{{ usuario?.email }}</p>
                <div class="plano">
                  <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${usuario?.assinatura?.tipo}.svg`" />
                  <div class="estado" :class="{ ativo: usuario?.assinatura?.ativo }">
                    <p>{{ usuario?.assinatura?.ativo ? 'Ativo' : 'Bloqueado' }}</p>
                  </div>
                  <h4>{{ usuario?.assinatura?.tipo }} {{ usuario?.assinatura?.plano }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { watchEffect, onMounted, ref, reactive } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreUsuarios, useStorePages } from '@stores'
import { helperRedirect } from '@helpers'
import Svgs from '@svgs'

const storeUsuarios = useStoreUsuarios()
const storePages = useStorePages()
const termoBusca = ref('')
const usuariosFiltrados = ref([])

const { mostrarModalSites } = storeToRefs(storeUsuarios)

const state = reactive({
  idUsuarioTransferir: '',
  pageParaTransferir: '',
  mostrarModalTransferencia: false
})

function fecharModal() {
  storeUsuarios.mostrarModalSites = false
  storePages.pagesUsuario = []
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function verSite(hotlink) {
  const url = `https://unic.page/${hotlink}`
  helperRedirect.redirecionar(url)
}

function verDominio(dominio) {
  const url = `https://${dominio}`
  helperRedirect.redirecionar(url)
}

function abrirModalTransferencia(pageId) {
  state.pageParaTransferir = pageId
  state.mostrarModalTransferencia = true
}

function selecionarUsuarioTransferir(idUsuario) {
  state.idUsuarioTransferir = idUsuario
}

function confirmarTransferencia() {
  if (!state.idUsuarioTransferir) {
    const mensagem = 'Selecione o usuário para transferir'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  const payload = {
    destino: {
      idUsuario: state.idUsuarioTransferir
    }
  }

  const idPage = state.pageParaTransferir
  const idUsuario = storeUsuarios.modals.idUsuario

  storePages.transferir(idUsuario, idPage, payload)

  fecharModalTransferencia()
  setTimeout(() => {
    window.location.reload()
  }, 2000)
}

function fecharModalTransferencia() {
  state.mostrarModalTransferencia = false
  state.idUsuarioTransferir = ''
  state.pageParaTransferir = ''
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

function filtrarUsuarios() {
  const termo = termoBusca.value.trim().toLowerCase()
  if (termo === '') {
    usuariosFiltrados.value = storeUsuarios.usuarios
  } else {
    usuariosFiltrados.value = storeUsuarios.usuarios.filter((usuario) => {
      return usuario.nome.toLowerCase().includes(termo) || usuario.email.toLowerCase().includes(termo) || usuario.assinatura.plano.toLowerCase().includes(termo) || usuario.assinatura.tipo.toLowerCase().includes(termo)
    })
  }
}

function carregarUsuarios() {
  storeUsuarios.receberTodos()
}

watchEffect(() => {
  usuariosFiltrados.value = storeUsuarios.usuarios
})

onMounted(() => {
  carregarUsuarios()
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  width: 100%;
  max-height: 100%;
  max-width: 900px;
  background-color: var(--cor-cinza-1);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  overflow-y: scroll;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.conteudo .titulo .foto {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg);
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

.lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 30px 30px 30px 30px;
}

.lista h6 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  width: 100%;
  text-align: center;
}

.lista .site {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(50% - 5px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0 0 0;
}
.lista .site .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.lista .site .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0;
}
.lista .site .nome p.com {
  color: var(--cor-azul);
}
.lista .site .nome p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}
.lista .site .nome h5 {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 20px 0;
}
.lista .site .nome h4 {
  display: flex;
  align-items: center;
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}
.lista .site .nome h4.online {
  color: var(--cor-verde);
}
.lista .site .nome h4.online span {
  background-color: var(--cor-verde);
}
.lista .site .nome h4.offline {
  color: var(--cor-cinza-5);
}
.lista .site .nome h4.offline span {
  background-color: var(--cor-cinza-4);
}
.lista .site .nome h4 span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  margin: 0 5px 0 0;
}
.lista .site .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.lista .site .botoes button {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 15px;
  border-radius: 10px;
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
  margin: 0 10px 0 0;
}
.lista .site .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}
.lista .site .botoes button:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.usuarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 30px;
  left: 30px;
  width: 100%;
  max-width: 500px;
  height: calc(100dvh - 150px);
  max-height: calc(100dvh - 150px);
  min-height: calc(100dvh - 150px);
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 30px 30px 0 30px;
  animation: fadeIn 0.3s linear;
  overflow-y: scroll;
}

.usuarios::-webkit-scrollbar-track {
  background-color: transparent;
}

.usuarios .topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 20px 0;
}

.usuarios .topo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuarios .topo button {
  background-color: transparent;
}

.usuarios .topo button svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.usuarios .topo button:hover svg {
  fill: var(--cor-branco);
}

.usuarios .input {
  margin: 0 0 10px 0;
}

.usuarios button.transferir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
  position: relative;
  transition: all 0.3s;
}

.usuarios button.transferir:hover {
  background-color: var(--cor-azul-escuro);
}

.usuarios .listaUsuarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  max-height: 450px;
  min-height: 450px;
  width: 100%;
}

.usuarios .usuario.ativo {
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-azul);
}

.usuarios .usuario {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px 10px 20px 20px;
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.usuarios .usuario .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.usuarios .usuario .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.usuarios .usuario .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.usuarios .usuario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.usuarios .usuario .nome .plano {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

.usuarios .usuario .nome .plano h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.usuarios .usuario .nome .plano img {
  max-height: 12px;
  max-height: 12px;
}

.usuarios .usuario .nome .plano .estado {
  padding: 2px 4px;
  border-radius: 50px;
  background-color: #f53a3a34;
  margin: 0 5px;
}

.usuarios .usuario .nome .plano .estado.ativo {
  background-color: #22bc5832;
}

.usuarios .usuario .nome .plano .estado.ativo p {
  color: var(--cor-verde);
}

.usuarios .usuario .nome .plano .estado p {
  font-size: var(--f0);
  font-family: var(--bold);
  color: var(--cor-vermelho);
  margin: 0;
  line-height: 1;
}

.usuarios .usuario .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.usuarios .usuario .botoes {
  display: flex;
  align-items: center;
}
.usuarios .usuario .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.usuarios .usuario .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 12px 0 0 0;
  transition: all 0.3s;
}
.usuarios .usuario .botoes button.estado {
  flex-direction: row;
  border-left: none;
  width: auto;
  pointer-events: none;
}
.usuarios .usuario .botoes button.estado.ativo p {
  color: var(--cor-verde);
}
.usuarios .usuario .botoes button.estado p {
  color: var(--cor-vermelho);
  margin: 0 20px 0 5px;
}
.usuarios .usuario .botoes button span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  box-shadow: 0 10px 30px 0 var(--cor-vermelho);
}
.usuarios .usuario .botoes button.ativo span {
  background-color: var(--cor-verde);
  box-shadow: 0 10px 30px 0 var(--cor-verde);
}
.usuarios .usuario .botoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }

  section.modal .titulo {
    padding: 20px;
  }

  .lista {
    padding: 20px;
  }

  .lista .site {
    width: 100%;
    padding: 20px;
  }

  .usuarios {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: calc(100dvh - 20px);
    max-height: calc(100dvh - 20px);
    min-height: calc(100dvh - 20px);
    padding: 20px;
  }
}
</style>
