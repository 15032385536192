<template>
  <section class="lista">
    <!-- Titulo -->
    <div class="topo">
      <div class="total">
        <Svgs nome="usuarios" />
        <h3>{{ storeUsuarios?.usuarios?.length }} usuários Unic</h3>
      </div>
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" spellcheck="false" placeholder="Buscar usuário" v-model="termoBusca" @input="filtrarUsuarios" />
      </div>
    </div>
    <!-- Itens -->
    <div class="itens">
      <div class="item" v-for="usuario in usuariosFiltrados" :key="usuario._id">
        <div class="info">
          <div class="foto" :style="`background-image: url('${usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
          <div class="nome">
            <h3>{{ usuario?.nome }}</h3>
            <p>{{ usuario?.email }}</p>
            <div class="plano">
              <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${usuario?.assinatura?.tipo}.svg`" />
              <div class="estado" :class="{ ativo: usuario?.assinatura?.ativo }">
                <p>{{ usuario?.assinatura?.ativo ? 'Ativo' : 'Bloqueado' }}</p>
              </div>
              <h4>{{ usuario?.assinatura?.tipo }} {{ usuario?.assinatura?.plano }}</h4>
            </div>
          </div>
        </div>
        <div class="botoes">
          <button class="sites" @click="abrirSites(usuario)">
            <Svgs nome="cursor" />
            <p>Ver Sites</p>
          </button>
          <button class="acesso" @click="abrirModalAcesso(usuario)">
            <Svgs nome="estrela" />
            <p>Liberar Acesso</p>
          </button>
          <button class="bloquear" @click="storeModal.abrirModalEdicao('bloquearAcesso', 'x', 'Bloquear acesso', usuario._id)">
            <Svgs nome="x" />
            <p>Bloquear Acesso</p>
          </button>
          <button class="senha" @click="storeModal.abrirModalEdicao('resetarSenha', 'cadeado', 'Resetar senha', usuario._id)">
            <Svgs nome="cadeado" />
            <p>Resetar Senha</p>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watchEffect, onMounted, ref } from 'vue'
import { useStoreUsuarios, useStorePages, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeUsuarios = useStoreUsuarios()
const storePages = useStorePages()
const storeModal = useStoreModal()
const termoBusca = ref('')
const usuariosFiltrados = ref([])

function abrirSites(usuario) {
  enviarDadosProsModals(usuario)

  const idUsuario = storeUsuarios.modals.idUsuario
  storeUsuarios.pagesUsuario = []
  storePages.receberPorUsuario(idUsuario)

  storeUsuarios.mostrarModalSites = true
}

function abrirModalAcesso(usuario) {
  enviarDadosProsModals(usuario)
  storeUsuarios.mostrarModalAcesso = true
}

function enviarDadosProsModals(usuario) {
  storeUsuarios.modals.idUsuario = usuario._id
  storeUsuarios.modals.email = usuario.email
  storeUsuarios.modals.imagemPerfil = usuario.imagemPerfil
}

function filtrarUsuarios() {
  const termo = termoBusca.value.trim().toLowerCase()
  if (termo === '') {
    usuariosFiltrados.value = storeUsuarios.usuarios
  } else {
    usuariosFiltrados.value = storeUsuarios.usuarios.filter((usuario) => {
      return usuario.nome.toLowerCase().includes(termo) || usuario.email.toLowerCase().includes(termo) || usuario.assinatura.plano.toLowerCase().includes(termo) || usuario.assinatura.tipo.toLowerCase().includes(termo)
    })
  }
}

function carregarUsuarios() {
  storeUsuarios.receberTodos()
}

watchEffect(() => {
  usuariosFiltrados.value = storeUsuarios.usuarios
})

onMounted(() => {
  carregarUsuarios()
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  min-height: 100dvh;
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.topo .total {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 0 0;
}

.topo .total svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.topo .total h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 8px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item .foto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .nome .plano {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

.item .nome .plano h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.item .nome .plano img {
  max-height: 12px;
  max-height: 12px;
}

.item .nome .plano .estado {
  padding: 2px 4px;
  border-radius: 50px;
  background-color: #f53a3a34;
  margin: 0 5px;
}

.item .nome .plano .estado.ativo {
  background-color: #22bc5832;
}

.item .nome .plano .estado.ativo p {
  color: var(--cor-verde);
}

.item .nome .plano .estado p {
  font-size: var(--f0);
  font-family: var(--bold);
  color: var(--cor-vermelho);
  margin: 0;
  line-height: 1;
}

.item .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.item .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  margin: 5px 0 5px 10px;
  padding: 10px 15px;
  transition: all 0.3s;
}
.item .botoes button:hover {
  background-color: var(--cor-cinza-4);
}
.item .botoes button:hover p {
  color: var(--cor-branco);
}
.item .botoes button:hover svg {
  fill: var(--cor-branco);
}
.item .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}
.item .botoes button svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-cinza-5);
  margin: 0 7px 0 0;
  transition: all 0.3s;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    flex-direction: column;
  }

  .topo {
    flex-direction: column;
  }

  .topo .total {
    max-width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
  }

  .item {
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }

  .item .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .item .botoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0 0 0;
  }
  .item .botoes button {
    margin: 10px 10px 0 0;
  }
}
</style>
